import { useContext, useState } from 'react';
import {
	I4goIdPostData,
	IAccessDetailsPostData,
	IAccessDetailsResponse,
	ITableDataResponse,
} from '../../pages/preAuthenticationDetail/models/PreAuthModel';
import { getAccessData, getSessionIdService, getTableData } from '../../services/PreAuthPageServices';
import ViewTicketDetailPageContext, {
	IViewTicketDetailPageContext,
} from '../ViewTicketPageContext/ViewTicketPageContext';
import PreAuthPageContext from './PreAuthPageContext';

interface IProps {
	children?: React.ReactNode;
}

const PreAuthPageContextProvider: React.FC<IProps> = ({ children }) => {
	const [tableName, setTableName] = useState<string>('');
	const [accessDetails, setAccessDetails] = useState<IAccessDetailsResponse>({});
	const [accessDetailsError, setAccessDetailsError] = useState('');
	const [sessionId, setSessionId] = useState<string>('');
	const [sessionIdError, setSessionIdError] = useState<string>('');
	const [recaptchaToken, setRecaptchaToken] = useState<string>('');
	const [isActiveSession, setIsActiveSession] = useState<boolean>(false);
	const { setIsCheckSessionValid } = useContext(ViewTicketDetailPageContext) as IViewTicketDetailPageContext;

	const getTableName = async (locationId: string) => {
		const tableRef = localStorage.getItem('tableRef') || '';
		const tableDataDetails: ITableDataResponse = await getTableData(locationId, tableRef);
		if (tableDataDetails?.table?.name) {
			setTableName(tableDataDetails.table.name);
		}
	};

	const getAccessToken = async (locationId: string, orderData: IAccessDetailsPostData) => {
		orderData.captchaResponse = recaptchaToken;
		orderData.challengeMode = "iframe";
		orderData.iframeElementId = "i4goFrame"; 
		const accessDetailsValue = await getAccessData(locationId, orderData);
		if (accessDetailsValue?.error) {
			setAccessDetailsError(accessDetailsValue?.error?.message);
		} else if (accessDetailsValue?.accessBlock) {
			localStorage.setItem('invoiceNumber', `${accessDetailsValue.invoiceNumber}`);
			setAccessDetails(accessDetailsValue);
		}
		setRecaptchaToken('');
		sessionStorage.clear();
	};

	const getSessionId = async (locationId: string, i4goIdData: I4goIdPostData) => {
		i4goIdData.invoiceNumber = `${localStorage.getItem('invoiceNumber')}`;
		const sessionIdResponse = await getSessionIdService(locationId, i4goIdData);
		if (sessionIdResponse?.error) {
			setIsCheckSessionValid(false);
			setSessionIdError(sessionIdResponse?.error?.message);
		} else if (sessionIdResponse?.sessionId) {
			setIsCheckSessionValid(true);
			localStorage.setItem('storedSessionId', sessionIdResponse?.sessionId);
			setSessionId(sessionIdResponse?.sessionId);
		}
	};

	const value = {
		tableName,
		getTableName,
		accessDetails,
		setAccessDetails,
		getAccessToken,
		accessDetailsError,
		setAccessDetailsError,
		sessionId,
		sessionIdError,
		getSessionId,
		setSessionIdError,
		setSessionId,
		setRecaptchaToken,
		isActiveSession,
		setIsActiveSession,
		recaptchaToken,
	};

	return <PreAuthPageContext.Provider value={value}>{children}</PreAuthPageContext.Provider>;
};

export default PreAuthPageContextProvider;
