import React, { useContext, useEffect, useState } from 'react';
import styles from './PreAuthenticationDetails.module.scss';
import { useHistory } from 'react-router-dom';
import BackArrow from '../../../assets/preAuthBackArrow.svg';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';
import { DELIVER_ORDER, ENTER_DETAILS, TABLE_DETAILS } from './labels';

interface IProps {
	storeId: string;
	tableName: string;
}

const PreAuthHeader: React.FC<IProps> = (props) => {
	const history = useHistory();
	const { storeId, tableName } = props;
	const tableRefId = localStorage.getItem('tableRef');
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const [merchantName, setMerchantName] = useState<string>('');
	const [merchantLogo, setMerchantLogo] = useState<string>('');

	useEffect(() => {
		if (!merchantData) {
			const shopName = localStorage.getItem('merchantName');
			const merchantLogoUrl = localStorage.getItem('merchantLogo');
			if (shopName) {
				setMerchantName(shopName);
			}
			if (merchantLogoUrl) {
				setMerchantLogo(merchantLogoUrl);
			}
		} else {
			localStorage.setItem('merchantName', merchantData.name);
			setMerchantName(merchantData.name);
			if (merchantData.logoUrl) {
				localStorage.setItem('merchantLogo', merchantData.logoUrl);
				setMerchantLogo(merchantData.logoUrl);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBackClick = () => {
		localStorage.removeItem('customerEmail');
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	return (
		<section id="preAuthHeaderContainer" className={`${styles.preAuthHeaderContainer} Container`}>
			<section className={`${styles.preAuthHeaderSubContainer} `}>
				<div className="six column row">
					<button className={styles.backArrow} onClick={handleBackClick}>
						<img src={BackArrow} className={styles.backButton} alt="back" />
					</button>
					<div className={styles.logo}>
						<img src={merchantLogo} className={`${styles.restaurantLogo}`} alt="restaurant name" />
					</div>
					<h3 className={styles.restaurantName}>{merchantName}</h3>
					<div className={styles.dividerContainer}></div>
					<div className={styles.enterDetailsText}>{ENTER_DETAILS}</div>
					<div className={styles.deliverOrderText}>{DELIVER_ORDER}</div>
					<div className={styles.tableText}>{`${TABLE_DETAILS} ${tableName}`}</div>
				</div>
			</section>
		</section>
	);
};

export default PreAuthHeader;
