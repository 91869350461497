import React, { useState, useContext, useEffect } from 'react';
import { Button, Divider, Form } from 'semantic-ui-react';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';
import AppContext, { IAppContext } from '../../Contexts/AppContext/AppContext';
import { ICustomerData } from './models/customerDataModels';
import { isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import shift4Logo from '../../../assets/shift4Logo.svg';
import cancelIcon from '../../../assets/cancelIcon.svg';
import lessThanIcon from '../../../assets/lessThanIcon.svg';
import styles from './CustomerDetails.module.scss';
import * as labels from './labels';
import { useHistory } from 'react-router-dom';
import LoaderIcon from '../../components/loaderIcon/LoaderIcon';
import Spinner from '../../components/spinner/Spinner';

import Toast from '../../components/toast/Toast';
import { INVALID_CODE_ERROR, SCRIPT_ERROR, INVALID_PHONE_NUMBER_ERROR } from '../home/labels';
import { resendCode } from '../../services/AppSessionServices';
import { MAX_PHONE_NUMBER_LENGTH } from '../../constants/constants';
import { isPreAuthEnabled, isValidEmail } from '../../utils/homeUtils';

interface IProps {
	match: {
		params: {
			storeId: string;
		};
	};
}

const CustomerDetails: React.FC<IProps> = (props) => {
	const history = useHistory();
	const [isUserDetailSent, setIsUserDetailSent] = useState<boolean>(false);
	const [customerName, setCustomerName] = useState<string>('');
	const [code, setCode] = useState<string>('');
	const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>('');
	const [merchantName, setMerchantName] = useState<string>('');
	const [merchantLogo, setMerchantLogo] = useState<string>('');
	const [isResendSmsLoading, setIsResendSmsLoading] = useState<boolean>(false);
	const [isCountryCodeAdded, setIsCountryCodeAdded] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const countryCode = merchantData && getCountryCallingCode(merchantData?.countryCode);
	const [customerEmail, setCustomerEmail] = useState<string>('');

	const {
		startSession,
		tempSessionId,
		isValidSession,
		sessionErrorStatus,
		setSessionErrorStatus,
		isClientTokenSet,
		setIsClientTokenSet,
		showLoaderIcon,
		toastMessage,
		updateToastMessage,
		checkSessionData,
		setIsValidSession,
	} = useContext(AppContext) as IAppContext;

	const { storeId } = props.match.params;
	const tableRefId = localStorage.getItem('tableRef');
	const itemInCart = localStorage.getItem('cartItems');

	useEffect(() => {
		const orderDetail = {
			shopName: merchantName,
			table: tableRefId,
			order: itemInCart && JSON.parse(itemInCart),
		};

		if (!merchantData) {
			const shopName = localStorage.getItem('merchantName');
			const merchantLogoUrl = localStorage.getItem('merchantLogo');
			if (shopName) {
				setMerchantName(shopName);
			}
			if (merchantLogoUrl) {
				setMerchantLogo(merchantLogoUrl);
			}
		} else {
			orderDetail.shopName = merchantData.name;
			localStorage.setItem('merchantName', merchantData.name);
			localStorage.setItem('orderDetail', JSON.stringify(orderDetail));
			setMerchantName(merchantData.name);
			if (merchantData.logoUrl) {
				localStorage.setItem('merchantLogo', merchantData.logoUrl);
				setMerchantLogo(merchantData.logoUrl);
			}
		}
		updateToastMessage('');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const normalizedPhoneNumber =
			countryCode && customerPhoneNumber.replace(/\D/g, '').substring(countryCode.length);

		if (normalizedPhoneNumber && normalizedPhoneNumber.length >= 10) {
			if (!isValidPhoneNumber(customerPhoneNumber)) {
				updateToastMessage(INVALID_PHONE_NUMBER_ERROR);
			}
		}
		if ((customerName && customerPhoneNumber && isValidPhoneNumber(customerPhoneNumber)) || isValidEmail(customerEmail)) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerName, customerPhoneNumber, customerEmail]);

	useEffect(() => {
		if (isClientTokenSet) {
			setIsUserDetailSent(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isClientTokenSet]);

	useEffect(() => {
		if (!isValidSession && sessionErrorStatus === 404) {
			updateToastMessage(INVALID_CODE_ERROR);
			setSessionErrorStatus(0);
		} else if (isValidSession) {
			history.push({
				pathname: `/${storeId}/orderDetail`,
				state: {
					storeId,
				},
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValidSession, sessionErrorStatus]);

	const handleContinueClick = () => {
		if (tableRefId && customerPhoneNumber) {
			const userPostData: ICustomerData = {
				tableRef: tableRefId,
				phoneNumber: `+${customerPhoneNumber.replace(/\D/g, '')}`,
				customerName: customerName,
			};
			localStorage.setItem('customerName', customerName);
			startSession(storeId, userPostData);
		}
	};

	const handleSmsCode = () => {
		if (storeId && tableRefId && tempSessionId && tempSessionId !== '') {
			setIsValidSession(false);
			checkSessionData(storeId, tempSessionId, code, tableRefId);
		}
	};

	const handleResendSmsCode = async () => {
		if (storeId && tempSessionId && tempSessionId !== '') {
			setCode('');
			setIsResendSmsLoading(true);
			const clientToken = localStorage.getItem('clientToken');
			if (clientToken) {
				const response = await resendCode(storeId, tempSessionId, clientToken);
				if (response?.error) {
					updateToastMessage(SCRIPT_ERROR);
				}
				setIsResendSmsLoading(false);
			}
		}
	};

	const handleBackClick = () => {
		setIsUserDetailSent(false);
		setIsClientTokenSet(false);
		setCustomerPhoneNumber('');
		setCode('');
	};

	const handleCloseClick = () => {
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	const handleEmailButton = () => {
		localStorage.setItem('customerEmail', customerEmail);
		history.push(`/${storeId}/startSession`);
	};

	const handleNameChange = (event: React.FormEvent<HTMLInputElement>) => {
		const name = event.currentTarget.value;
		setCustomerName(name);
	};

	const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
		const email = event.currentTarget.value;
		setCustomerEmail(email);
	};

	const handleCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
		const codeValue = event.currentTarget.value;
		if (codeValue.length <= 6) {
			setCode(codeValue);
		}
	};

	const handleDuplicateCountryCode = (updatedNumber: string): string => {
		let phoneNumberInput = updatedNumber.replace(/\D/g, '');
		const phoneNumberWithoutCode = countryCode && phoneNumberInput.substring(countryCode.length);

		if (phoneNumberWithoutCode && phoneNumberWithoutCode.startsWith(countryCode)) {
			phoneNumberInput = phoneNumberWithoutCode.slice(countryCode.length);
			phoneNumberInput = `${countryCode}${phoneNumberInput}`;
		}
		return `+${phoneNumberInput}`;
	};

	const handlePhoneNumberChange = (phoneNumberEvent: React.FormEvent<HTMLInputElement>) => {
		const phoneNumber: string = phoneNumberEvent.currentTarget.value;
		let numericInput = phoneNumber.replace(/\D/g, '');

		if (countryCode && numericInput.length > countryCode.length + MAX_PHONE_NUMBER_LENGTH) {
			return;
		}

		if (numericInput.length === 0) {
			setCustomerPhoneNumber(numericInput);
			return;
		}

		if (!isCountryCodeAdded) {
			numericInput = `+${countryCode}${numericInput}`;
			setIsCountryCodeAdded(true);
		} else {
			numericInput = `+${numericInput}`;
		}

		numericInput = handleDuplicateCountryCode(numericInput);

		setCustomerPhoneNumber(numericInput);
	};

	return (
		<>
			{isResendSmsLoading && <Spinner />}
			<section className={`${styles.orderDetails}  Container`}>
				{isUserDetailSent ? (
					<div className={`${styles.navButton}  ui grid`}>
						<div className="six column row">
							<div className="left floated column">
								<Button icon className="float left backButton" onClick={handleBackClick}>
									<img src={lessThanIcon} className={styles.backButton} alt="back" />
								</Button>
							</div>
							<div className="right floated column"></div>
						</div>
					</div>
				) : (
					<div className={`${styles.navButton}  ui grid`}>
						<div className="six column row">
							<div className="left floated column"></div>
							<div className="right floated column">
								<Button
									icon
									className="float right closeButton"
									onClick={handleCloseClick}
									disabled={showLoaderIcon}
								>
									<img src={cancelIcon} className={styles.closeButton} alt="close" />
								</Button>
							</div>
						</div>
					</div>
				)}

				<section className={`${styles.orderPage} `}>
					{merchantLogo && (
						<div className={styles.logo}>
							<img src={merchantLogo} className={`${styles.restaurantLogo}`} alt="restaurant name " />
						</div>
					)}

					<h3 className={styles.restaurantName}>{merchantName}</h3>
					<Divider />
					<div className={styles.orderForm}>
						{isPreAuthEnabled() ? (
							<>
								<h4 className={`${styles.textCentered} ${styles.nameLabel}`}>{labels.EMAIL_QUESTION}</h4>
								<p className={`${styles.textCentered} ${styles.deliveryMessage}`}>
										{labels.SECURE_PAYMENT_VERIFICATION}
								</p>
								<Form className={styles.form}>
									<Form.Field>
										<label htmlFor="email" className={styles.label}>
											{labels.EMAIL}
										</label>
										<input
											placeholder={labels.EMAIL_PLACEHOLDER}
											name="email"
											type="email"
											className={`${styles.textBox}`}
											maxLength={255}
											value={customerEmail}
											onChange={(e) => handleEmailChange(e)}
										/>
									</Form.Field>
									<Form.Field>
										<Button
											id="continueBtn"
											type="button"
											primary
											className={`${styles.continueBtn} fluid`}
											onClick={handleEmailButton}
											disabled={isDisabled}
										>
											{showLoaderIcon && <LoaderIcon />}
											{labels.CONTINUE_BUTTON}
										</Button>
									</Form.Field>
								</Form>
							</>	
						): (
							<>
								{isUserDetailSent ? (
									<>
										<div className={styles.confirmOrder}>
											<h4 className={`${styles.textCentered} ${styles.nameLabel}`}>
												{labels.MESSAGE_CONFIRM}
											</h4>
											<p className={`${styles.textCentered} ${styles.deliveryMessage}`}>
												{labels.MOBILE_LINK_MESSAGE.message_one}
												<div>
													{labels.MASKED_NUMBER}
													{customerPhoneNumber?.toString()?.substr(-4)}
												</div>
											</p>
											<Form className={styles.form}>
												<Form.Field>
													<label htmlFor="code" className={styles.label}>
														{labels.SMS_CODE}
													</label>
													<input
														placeholder={labels.SMS_CODE_PLACEHOLDER}
														name="code"
														type="number"
														maxLength={6}
														value={code.toString()}
														onKeyDown={(evt) =>
															['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()
														}
														className={`${styles.textBox}`}
														onChange={(e) => handleCodeChange(e)}
													/>
												</Form.Field>
											</Form>
											<label className={`${styles.textCentered} ${styles.confirmationLink}`}>
												<span className={styles.receiveLink}>{labels.RECEIVE_LINK_QUESTION}</span>
												<span className={styles.resendText}>
													<button className={styles.resendBtn} onClick={() => handleResendSmsCode()}>
														{labels.RESEND}
													</button>
												</span>
											</label>
											<Button
												id="continueBtn"
												type="button"
												disabled={code.length !== 6}
												primary
												className={`${styles.continueBtn} fluid`}
												onClick={handleSmsCode}
											>
												{showLoaderIcon && <LoaderIcon />}
												{labels.CONTINUE_BUTTON}
											</Button>
										</div>
									</>
								) : (
									<>
										<h4 className={`${styles.textCentered} ${styles.nameLabel}`}>{labels.NAME_QUESTION}</h4>
										<p className={`${styles.textCentered} ${styles.deliveryMessage}`}>
											{labels.DELIVERY_FORM_PROMPT.message_one}
										</p>

										<Form className={styles.form}>
											<Form.Field>
												<label htmlFor="name" className={styles.label}>
													{labels.NAME}
												</label>
												<input
													placeholder="Name"
													name="name"
													type="text"
													className={`${styles.textBox}`}
													maxLength={44}
													value={customerName}
													onChange={(e) => handleNameChange(e)}
												/>
											</Form.Field>
											<Form.Field className={styles.formField}>
												<label htmlFor="phone" className={styles.label}>
													{labels.PHONE_NUMBER}
												</label>
												<input
													className={`${styles.textBox}`}
													type="tel"
													placeholder="Phone number"
													name="phone"
													value={customerPhoneNumber}
													onChange={(e) => handlePhoneNumberChange(e)}
												/>
											</Form.Field>
											<Form.Field>
												<p className={styles.smallText}>{labels.DELIVERY_FORM_PROMPT.message_two}</p>
											</Form.Field>
											<Form.Field>
												<Button
													type="button"
													primary
													className={`${styles.continueBtn} fluid`}
													onClick={handleContinueClick}
													disabled={isDisabled}
												>
													{showLoaderIcon && <LoaderIcon />}
													{labels.SEND_CONFIRMATION_TEXT}
												</Button>
											</Form.Field>
										</Form>
									</>
								)}
							</>
						)}
					</div>
				</section>
				<img src={shift4Logo} className={`${styles.shift4Logo} ${styles.fixedBottom}`} alt="Shift4 logo" />
			</section>
			{toastMessage?.length > 0 && <Toast message={toastMessage} />}
		</>
	);
};

export default CustomerDetails;
