import { apiCall } from '../api/apiCall';
import { IPlaceOrderResponse, IOrderDetailRequestObject } from '../pages/home/models/orderDetailModels';
import { isPreAuthEnabled } from '../utils/homeUtils';

/**
 * This below function call the api for posting the order data
 * @param {string} locationId - merchant locationId received from the scanned QR data.
 * @param {ICustomerData} orderData - posting order data containing shopName , Table name, Items , Modifiers.
 */
export const postOrderData = async (
	locationId: string,
	sessionId: string,
	orderData: IOrderDetailRequestObject
): Promise<IPlaceOrderResponse> => {
	const existingSessionId = localStorage.getItem('storedSessionId');
	const existingTableRef = localStorage.getItem('tableRef');
	const customerName = localStorage.getItem('customerName') || '';

	if (isPreAuthEnabled()) {
		orderData.customerName = customerName;

		return await apiCall(
			`/api/v2/merchants/${locationId}/order/${existingSessionId}?tableRef=${existingTableRef}`,
			'POST',
			{},
			orderData
		);
	}
	return await apiCall(`/api/v1/merchants/${locationId}/order/${sessionId}`, 'POST', {}, orderData);
};
