/**
 * This below function validates the table reference as valid guid.
 * @param {string} tableRefValue - Table reference from url.
 * @returns {boolean} - returns valid guid or not as boolean
 */
export const isValidGuid = (tableRefValue: string): boolean => {
	const guid = tableRefValue?.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
	if (guid !== null) {
		return true;
	}
	return false;
};

export const isPreAuthEnabled = (): boolean => {
	const preAuthFlag = localStorage.getItem('preAuth');
	return preAuthFlag === 'true';
};

/**
 * Validates an email address using a regular expression.
 * This checks for a basic email pattern: a string before "@" followed by a domain.
 * 
 * @param {string} email - The email address to validate.
 * @returns {boolean} true if the email is valid, otherwise false.
 */
export const isValidEmail = (email: string): boolean => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

/**
 * Extracts the store ID from the given URL pathname.
 *
 * @param {string} pathname - The URL pathname to extract the store ID from.
 * @returns {string} - The extracted store ID or an empty string if not found.
 */
export const extractStoreIdFromURL = (pathname: string): string => {
	const pathSegments = pathname.split('/').filter((segment) => segment !== '');

	// Return the first non-empty path segment or null if none found
	return pathSegments[0];
};
